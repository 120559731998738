<template>
  <div class="home">
    <h1>Open a chatroom on the left to start chatting!</h1>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped>
.home {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #282828;
  user-select: none;
}
h1 {
  color: #808080;
}
</style>
