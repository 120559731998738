<template>
  <div class="chatMessage">
    <img :src="userimage" alt="pfp" />
    <div class="rightPart">
      <p class="userName">{{ username }}</p>
      <pre class="messageContent">{{ msg }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatMessage",
  props: {
    username: String,
    userimage: String,
    msg: String,
  },
};
</script>

<style scoped>
.chatMessage {
  display: flex;
  width: fit-content;
  min-height: 3rem;
  margin: 1rem 0px;
}
img {
  height: 3rem;
  width: auto;
  margin-right: 0.6rem;
  border-radius: 50%;
}
p,
pre {
  padding: 0px;
  margin: 0px;
  font-family: "Open Sans", sans-serif;
  color: white;
}
.rightPart {
  flex-grow: 1;
}
.userName {
  font-weight: 800;
}
.messageContent {
  color: #dddddd;
}
</style>
