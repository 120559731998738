<template>
  <div class="room">
    <div class="header">
      <h3>{{ $route.params.id }}</h3>
      <img
        src="@/assets/ex/connect.svg"
        alt=""
        class="button"
        srcset=""
        @click="call"
      />
    </div>
    <div class="chats">
      <!-- {{ $parent.messages[$route.params.id] }} -->
      <!-- <ChatMessage v-bind="welMsg"></ChatMessage> -->
      <ChatMessage
        v-for="msg of $parent.messages[$route.params.id]
          ? $parent.messages[$route.params.id]
          : []"
        :key="msg.id"
        v-bind="msg"
      ></ChatMessage>
    </div>
    <div :class="'chatter room-' + $route.params.id">
      <textarea
        placeholder="Type something here!"
        class="chatInput"
        rows="1"
        @input="areaInput"
        @keydown="enterCheck"
      ></textarea>
      <button @click="sendMsg">Send</button>
    </div>
  </div>
</template>

<script>
import ChatMessage from "@/components/ChatMessage.vue";

export default {
  name: "Room",
  mounted() {
    if (!this.$parent.Chatrooms.includes(this.$route.params.id)) {
      this.$router.push("/");
    }
  },
  updated() {
    let elem = document.querySelector(".chats");
    // if (
    //   elem.scrollTop + elem.height + 50 >
    //   document.querySelector(".chats").scrollHeight
    // )
    elem.scrollTop = document.querySelector(".chats").scrollHeight;
    return "ass";
  },
  data() {
    return {
      welMsg: {
        msg: "Welcome to this chat",
        username: "Welcomer",
        userimage: "/img/LogoBoxD.svg",
      },
    };
  },
  methods: {
    enterCheck(e) {
      // let area = document.querySelector(
      //   ".chatter.room-" + this.$route.params.id + " textarea"
      // );
      if (e.key === "Enter" && !e.shiftKey) {
        this.sendMsg();
      }
    },
    call() {
      this.$parent.vcClient.JoinRoom(this.$route.params.id);
    },
    areaInput() {
      let area = document.querySelector(
        ".chatter.room-" + this.$route.params.id + " textarea"
      );
      if (area.value.trim() === "") {
        area.value = "";
      }
      let linelength = area.value.split("\n").length;
      linelength = linelength > 3 ? 3 : linelength;
      area.rows = linelength;
    },
    sendMsg() {
      let area = document.querySelector(
        ".chatter.room-" + this.$route.params.id + " textarea"
      );
      let socket = window.socket;
      socket.emit("chat", {
        msg: area.value,
        room: this.$route.params.id,
      });
      area.value = "";
    },
  },
  components: {
    ChatMessage,
  },
};
</script>

<style scoped>
.room {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #282828;
}
.chats {
  height: 100%;
  width: 100%;
  padding: 0px 1rem;
  overflow-y: scroll;
}
.chatter {
  height: fit-content;
  display: flex;
}
.header {
  display: flex;
  height: 3rem;
  background-color: #080808;
  justify-content: space-between;
  align-items: center;
}
img {
  height: 100%;
  width: auto;
  padding: 0.4rem;
}
h3 {
  color: #fff;
  font-size: 2rem;
  padding: 0px;
  margin: 0px;
}
textarea {
  flex: 1;
  font-size: 1.5rem;
  resize: none;
  background-color: #323232;
  outline: none;
  color: white;
  border: none;
  /* border: solid black 0.2rem; */
}
</style>
